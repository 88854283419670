import React, { useRef, useState, useEffect } from "react"
import Layout from "~components/layout"
import SEO from "~components/seo"
import { useStaticQuery, graphql} from "gatsby"
import BodyClassName from "react-body-classname"
import jsonp from "fetch-jsonp"
import RichText from "~components/richText"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { useLocation } from "@reach/router"

import styled from "styled-components"
import theme from "~theme/theme"

import { Input, MenuPageWrapper, MenuPageContent, MenuColumns, MenuImage, Person, PersonImage } from "../theme/common"

import MenuPageSidebar from '~components/MenuPageSidebar'

const ContactPage = ({ data }) => {
  const formUrl = 'https://citiespeoplelove.us10.list-manage.com/subscribe/post?u=4e75ee7228e4e0ad9be57e270&amp;id=4139d17f8a'
  const verificationId = 'b_4e75ee7228e4e0ad9be57e270_4139d17f8a'

  const formRef = useRef()
  const [formResponse, setFormResponse] = useState(false)

  const loc = useLocation()

  const [focusSignup, setFocusSignup] = useState(loc.search.includes('?subscribe'))

  useEffect(() => {
    setFocusSignup(loc.search.includes('?subscribe'))
  }, [loc])

  

  const submitForm = async (e) => {
    e.preventDefault()
    trackCustomEvent({
      category: "Sign up button",
      action: "Click",
      label: "Email sign ups",
    })
    const formString = new URLSearchParams(new FormData(formRef.current)).toString()
    const postUrl = formRef.current.getAttribute('action').replace('post', 'post-json')+`&${formString}`
    jsonp(postUrl, {
      jsonpCallback: 'c',
    }).then(async function(response) {
      const data = await response.json()
      setFormResponse(data)
    })
  }

  return (
    <Layout>
      <SEO title="Contact" />
      <BodyClassName className="info-page">
        <MenuPageWrapper>
          <MenuPageSidebar />
          <MenuPageContent>
            <MenuColumns>
              <div>
                <RichText content={data.sanitySiteSettings._rawAboutText}/>
              </div>
              <div>
                <RichText content={data.sanitySiteSettings._rawNewsletterText}/>
                {formResponse && formResponse.result === 'success' &&
                  <p>Thank you for subscribing.</p>
                }
                {(!formResponse || (formResponse && formResponse.result !== 'success')) &&
                  <form onSubmit={(e) => submitForm(e)} ref={formRef} action={formUrl} method="post">
                    <input style={{ position: 'absolute', left: '-5000px'}} aria-hidden="true" type="text" name={verificationId} tabIndex="-1" value="" />
                    <Fieldset>
                      <Input placeholder="Type email here" name="EMAIL" autoFocus={focusSignup} key={focusSignup}/>
                      <Button name="subscribe" type="submit">Subscribe</Button>
                    </Fieldset>
                    {formResponse && formResponse.result === 'error' &&
                    <ErrorMessage dangerouslySetInnerHTML={{ __html: formResponse.msg }} />
                    }
                  </form>
                }
              </div>
            </MenuColumns>
          </MenuPageContent>
        </MenuPageWrapper>
      </BodyClassName>
    </Layout>
  )
}

export const query = graphql`
  {
    sanitySiteSettings {
      _rawAboutText
      _rawNewsletterText
    }
  }
`

const ErrorMessage = styled.p`
  color: ${theme.colours.red};
`

const Fieldset = styled.div`
  display: flex;
  > *:first-child {
    flex: 1;
  }
  > *:last-child {
    flex: 0;
  }
`
const Button = styled(Input).attrs({
  as: "button"
})``

export default ContactPage
